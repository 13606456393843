import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Let's me show a simple trick that could speed up your workflow while working with CSS.`}</p>
    <p>{`Sometimes, you need the CSS of a component that you might have found on a website you like.
Many times, I am looking for design inspiration and when I find something that I like, I get curious about how the styling was made...`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/15VYQKWei2k" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`This simple trick that I am going to show you, it is going to help you to extract the CSS from any given website.`}</p>
    <p>{`I felt in love with this feature as soon as I discovered its existence.`}</p>
    <p>{`It is super easy!`}</p>
    <ol>
      <li parentName="ol">
        <b>Go to the website</b> you like.
      </li>
      <li parentName="ol">
        <b>Open the Chrome DevTools</b>
      </li>
      <li parentName="ol">
        <b>Select the component</b> that you want to extract the CSS.
      </li>
      <li parentName="ol">
        <b>Right-click on the element</b> in the Chrome DevTools and...
      </li>
      <li parentName="ol">{`Click the `}<b>{`Copy -> Copy Styles`}</b></li>
      <li parentName="ol">{`Copy the CSS in your code!`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--6KPYlA6x--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/mnmdx50da1bvnw2srk17.png",
        "alt": "Image"
      }}></img></p>
    <p>{`Done. That's all you really need to copy the CSS styles from any part of any given website!`}</p>
    <p>{`Pretty amazing!`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      